/**
 * @author Karthik <karthik.x@314ecorp.com>
 * @description Entry point to Dexit
 */

import { H } from 'highlight.run';
import { createRoot } from 'react-dom/client';

import * as serviceWorker from './components/app/serviceWorkerRegistration';
import App from './components/app/App';
import EventBus, { Events } from '@dexit/common/utils/EventBus';
import config from 'configs/index';
import env from '@dexit/common/utils/Environment';
import { initializeFaro } from 'components/faro/initializeFaro';

H.init(config.highlight.projectID, config.highlight.debug);
initializeFaro();

serviceWorker.register({
	onUpdate: (__, metadata) => setTimeout(() => EventBus.publish(Events.RELEASE_PROMPT, metadata), 1000),
});

if (typeof globalThis !== 'undefined' && env.isLocalhost()) {
	import('react-scan').then(({ scan }) => {
		scan({ enabled: true, log: false });
	});
}

const root = createRoot(document.getElementById('root') ?? document.body);
root.render(<App />);
