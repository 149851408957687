/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Wrapper for AgGrid with antd pagination
 */

import React, { forwardRef, useRef, useState, useImperativeHandle } from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import { AgGridReact } from 'ag-grid-react';
import { ColumnMenuTab, ColumnState, ColumnStateParams, GridOptions, SortChangedEvent } from 'ag-grid-community';
import { Pagination, PaginationProps, Spin } from 'antd';

import useMatomo from '@dexit/admin/src/matomo/useMatomo';
import { ACTION_DIMENSIONS_RESET } from 'configs/matomo.config';

export interface AgGridProps extends Omit<GridOptions, 'onSortChanged'> {
	containerClassName?: string;
	containerStyle?: React.CSSProperties;
	className?: string;
	pageTitle?: string;
	totalPages?: number;
	onPageChange?: PaginationProps['onChange'];
	onSortChanged?: (sortedColumn: ColumnState['colId'], sortDirection: ColumnStateParams['sort']) => void;
}

export const HEADER_HEIGHT = 41;
export const CELL_HEIGHT = 38;
const PAGE_SIZE_OPTIONS = [10, 25, 50];
export const DEFAULT_PAGE_SIZE = 25;
const DEFAULT_COLUMNS_DEF = {
	resizable: true,
	unSortIcon: true,
	sortable: true,
	suppressHeaderMenuButton: true,
	menuTabs: [] as ColumnMenuTab[],
};

const Grid = forwardRef<AgGridReact, AgGridProps>((props, ref) => {
	const {
		containerClassName,
		containerStyle,
		pagination = true,
		loading,
		totalPages,
		onPageChange,
		onSortChanged,
		...restProps
	} = props;

	const gridRef = useRef<AgGridReact>(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
	const { trackEvent } = useMatomo();

	useImperativeHandle(ref, () => {
		return gridRef.current!;
	});

	const handlePageChange = (page: number, pageSize: number): void => {
		setCurrentPage(page);
		gridRef.current?.api.paginationGoToPage(page - 1);
		setPageSize(pageSize);
		onPageChange?.(page, pageSize);
		if (props.pageTitle) {
			trackEvent({
				category: props.pageTitle,
				action: 'Pagination Changed',
				name: _.toString(page),
				customDimensions: [...ACTION_DIMENSIONS_RESET],
			});
		}
	};
	const handleSortChanged = (event: SortChangedEvent) => {
		const column = _.filter(event.api.getColumnState(), (item) => !_.isNull(item.sort));
		const sortedColumn = column?.[0]?.colId;
		const sortDirection = column?.[0]?.sort;
		onSortChanged?.(sortedColumn, sortDirection);
	};

	return (
		<div style={{ display: 'flex', flexFlow: 'column' }}>
			<div className={clsx('ag-theme-alpine', containerClassName)} style={{ width: '100%', ...containerStyle }}>
				<AgGridReact
					ref={gridRef}
					loading={loading}
					pagination={pagination}
					headerHeight={HEADER_HEIGHT}
					rowHeight={CELL_HEIGHT}
					animateRows={true}
					suppressColumnMoveAnimation={true}
					suppressMenuHide={false}
					suppressPaginationPanel={true}
					defaultColDef={DEFAULT_COLUMNS_DEF}
					paginationPageSize={pageSize}
					overlayNoRowsTemplate='No data found'
					enableCellTextSelection={true}
					loadingOverlayComponent={() => <Spin />}
					onGridReady={(event) => event.api.sizeColumnsToFit()}
					onSortChanged={handleSortChanged}
					{...restProps}
				/>
			</div>
			{pagination && (
				<div style={{ padding: '10px 10px 15px 0px' }} className='flex-justify-end'>
					<Pagination
						size='small'
						// showSizeChanger
						showQuickJumper
						className='table-pagination-class'
						total={totalPages ?? _.size(props.rowData)}
						pageSizeOptions={PAGE_SIZE_OPTIONS}
						defaultPageSize={DEFAULT_PAGE_SIZE}
						current={currentPage}
						onChange={handlePageChange}
					/>
				</div>
			)}
		</div>
	);
});

export default Grid;
