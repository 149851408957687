/* tslint:disable */
/* eslint-disable */
/**
 * DexIt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AllFaxRequestModel } from '../Models';
// @ts-ignore
import { AvailableNumberResponseModel } from '../Models';
// @ts-ignore
import { FaxDetailsResponseModel } from '../Models';
// @ts-ignore
import { FaxPurchaseRequestModel } from '../Models';
// @ts-ignore
import { FaxQueueRequestModel } from '../Models';
// @ts-ignore
import { LamlRequestModel } from '../Models';
// @ts-ignore
import { LamlResponseModel } from '../Models';
// @ts-ignore
import { PurchasedNumberResponseModel } from '../Models';
// @ts-ignore
import { ResponseModel } from '../Models';
// @ts-ignore
import { SendFaxRequestModel } from '../Models';
// @ts-ignore
import { UpdateFaxRequestModel } from '../Models';
// @ts-ignore
import { UpdateNumberRequestModel } from '../Models';
/**
 * FaxApi - axios parameter creator
 * @export
 */
export const FaxApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [countryCode] 
         * @param {string} [areacode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        availableNumbers: async (countryCode?: string, areacode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/fax/availableNumbers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (countryCode !== undefined) {
                localVarQueryParameter['country_code'] = countryCode;
            }

            if (areacode !== undefined) {
                localVarQueryParameter['areacode'] = areacode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LamlRequestModel} lamlRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLamlBin: async (lamlRequestModel: LamlRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lamlRequestModel' is not null or undefined
            assertParamExists('createLamlBin', 'lamlRequestModel', lamlRequestModel)
            const localVarPath = `/api/v1/fax/createLamlBin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lamlRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLamlBin: async (id?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/fax/deleteLamlBin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNumber: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteNumber', 'id', id)
            const localVarPath = `/api/v1/fax/number`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSchedule: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSchedule', 'id', id)
            const localVarPath = `/api/v1/fax/deleteSchedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faxStatusCallBack: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/api/v1/fax/faxStatusCallBack`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AllFaxRequestModel} allFaxRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllFaxes: async (allFaxRequestModel: AllFaxRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'allFaxRequestModel' is not null or undefined
            assertParamExists('listAllFaxes', 'allFaxRequestModel', allFaxRequestModel)
            const localVarPath = `/api/v1/fax`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(allFaxRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLamlBins: async (id?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/fax/listLamlBins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FaxQueueRequestModel} faxQueueRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveFaxToQueue: async (faxQueueRequestModel: FaxQueueRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'faxQueueRequestModel' is not null or undefined
            assertParamExists('moveFaxToQueue', 'faxQueueRequestModel', faxQueueRequestModel)
            const localVarPath = `/api/v1/fax/moveFaxToQueue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(faxQueueRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        numbers: async (id?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/fax/numbers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FaxPurchaseRequestModel} faxPurchaseRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseNumber: async (faxPurchaseRequestModel: FaxPurchaseRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'faxPurchaseRequestModel' is not null or undefined
            assertParamExists('purchaseNumber', 'faxPurchaseRequestModel', faxPurchaseRequestModel)
            const localVarPath = `/api/v1/fax/purchaseNumber`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(faxPurchaseRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiveFax: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/api/v1/fax/receiveFax`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SendFaxRequestModel} sendFaxRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendFax: async (sendFaxRequestModel: SendFaxRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendFaxRequestModel' is not null or undefined
            assertParamExists('sendFax', 'sendFaxRequestModel', sendFaxRequestModel)
            const localVarPath = `/api/v1/fax/sendFax`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendFaxRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNow: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sendNow', 'id', id)
            const localVarPath = `/api/v1/fax/sendNow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateFaxRequestModel} updateFaxRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFax: async (updateFaxRequestModel: UpdateFaxRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateFaxRequestModel' is not null or undefined
            assertParamExists('updateFax', 'updateFaxRequestModel', updateFaxRequestModel)
            const localVarPath = `/api/v1/fax/updateFax`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFaxRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateNumberRequestModel} updateNumberRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNumber: async (updateNumberRequestModel: UpdateNumberRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateNumberRequestModel' is not null or undefined
            assertParamExists('updateNumber', 'updateNumberRequestModel', updateNumberRequestModel)
            const localVarPath = `/api/v1/fax/number`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNumberRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FaxApi - functional programming interface
 * @export
 */
export const FaxApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FaxApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [countryCode] 
         * @param {string} [areacode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async availableNumbers(countryCode?: string, areacode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AvailableNumberResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.availableNumbers(countryCode, areacode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FaxApi.availableNumbers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {LamlRequestModel} lamlRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLamlBin(lamlRequestModel: LamlRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LamlResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLamlBin(lamlRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FaxApi.createLamlBin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLamlBin(id?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLamlBin(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FaxApi.deleteLamlBin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNumber(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNumber(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FaxApi.deleteNumber']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSchedule(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSchedule(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FaxApi.deleteSchedule']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faxStatusCallBack(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faxStatusCallBack(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FaxApi.faxStatusCallBack']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AllFaxRequestModel} allFaxRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAllFaxes(allFaxRequestModel: AllFaxRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FaxDetailsResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAllFaxes(allFaxRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FaxApi.listAllFaxes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listLamlBins(id?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LamlResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listLamlBins(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FaxApi.listLamlBins']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FaxQueueRequestModel} faxQueueRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveFaxToQueue(faxQueueRequestModel: FaxQueueRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moveFaxToQueue(faxQueueRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FaxApi.moveFaxToQueue']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async numbers(id?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PurchasedNumberResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.numbers(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FaxApi.numbers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FaxPurchaseRequestModel} faxPurchaseRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purchaseNumber(faxPurchaseRequestModel: FaxPurchaseRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purchaseNumber(faxPurchaseRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FaxApi.purchaseNumber']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiveFax(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.receiveFax(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FaxApi.receiveFax']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SendFaxRequestModel} sendFaxRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendFax(sendFaxRequestModel: SendFaxRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendFax(sendFaxRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FaxApi.sendFax']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendNow(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendNow(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FaxApi.sendNow']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateFaxRequestModel} updateFaxRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFax(updateFaxRequestModel: UpdateFaxRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFax(updateFaxRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FaxApi.updateFax']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateNumberRequestModel} updateNumberRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNumber(updateNumberRequestModel: UpdateNumberRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNumber(updateNumberRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FaxApi.updateNumber']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FaxApi - factory interface
 * @export
 */
export const FaxApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FaxApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [countryCode] 
         * @param {string} [areacode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        availableNumbers(countryCode?: string, areacode?: string, options?: any): AxiosPromise<Array<AvailableNumberResponseModel>> {
            return localVarFp.availableNumbers(countryCode, areacode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LamlRequestModel} lamlRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLamlBin(lamlRequestModel: LamlRequestModel, options?: any): AxiosPromise<LamlResponseModel> {
            return localVarFp.createLamlBin(lamlRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLamlBin(id?: string, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.deleteLamlBin(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNumber(id: string, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.deleteNumber(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSchedule(id: string, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.deleteSchedule(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faxStatusCallBack(options?: any): AxiosPromise<void> {
            return localVarFp.faxStatusCallBack(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AllFaxRequestModel} allFaxRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllFaxes(allFaxRequestModel: AllFaxRequestModel, options?: any): AxiosPromise<FaxDetailsResponseModel> {
            return localVarFp.listAllFaxes(allFaxRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLamlBins(id?: string, options?: any): AxiosPromise<Array<LamlResponseModel>> {
            return localVarFp.listLamlBins(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FaxQueueRequestModel} faxQueueRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveFaxToQueue(faxQueueRequestModel: FaxQueueRequestModel, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.moveFaxToQueue(faxQueueRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        numbers(id?: string, options?: any): AxiosPromise<Array<PurchasedNumberResponseModel>> {
            return localVarFp.numbers(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FaxPurchaseRequestModel} faxPurchaseRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseNumber(faxPurchaseRequestModel: FaxPurchaseRequestModel, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.purchaseNumber(faxPurchaseRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiveFax(options?: any): AxiosPromise<void> {
            return localVarFp.receiveFax(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SendFaxRequestModel} sendFaxRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendFax(sendFaxRequestModel: SendFaxRequestModel, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.sendFax(sendFaxRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNow(id: string, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.sendNow(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateFaxRequestModel} updateFaxRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFax(updateFaxRequestModel: UpdateFaxRequestModel, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.updateFax(updateFaxRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateNumberRequestModel} updateNumberRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNumber(updateNumberRequestModel: UpdateNumberRequestModel, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.updateNumber(updateNumberRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FaxApi - interface
 * @export
 * @interface FaxApi
 */
export interface FaxApiInterface {
    /**
     * 
     * @param {string} [countryCode] 
     * @param {string} [areacode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaxApiInterface
     */
    availableNumbers(countryCode?: string, areacode?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<AvailableNumberResponseModel>>;

    /**
     * 
     * @param {LamlRequestModel} lamlRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaxApiInterface
     */
    createLamlBin(lamlRequestModel: LamlRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<LamlResponseModel>;

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaxApiInterface
     */
    deleteLamlBin(id?: string, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaxApiInterface
     */
    deleteNumber(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaxApiInterface
     */
    deleteSchedule(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaxApiInterface
     */
    faxStatusCallBack(options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {AllFaxRequestModel} allFaxRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaxApiInterface
     */
    listAllFaxes(allFaxRequestModel: AllFaxRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<FaxDetailsResponseModel>;

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaxApiInterface
     */
    listLamlBins(id?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<LamlResponseModel>>;

    /**
     * 
     * @param {FaxQueueRequestModel} faxQueueRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaxApiInterface
     */
    moveFaxToQueue(faxQueueRequestModel: FaxQueueRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaxApiInterface
     */
    numbers(id?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<PurchasedNumberResponseModel>>;

    /**
     * 
     * @param {FaxPurchaseRequestModel} faxPurchaseRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaxApiInterface
     */
    purchaseNumber(faxPurchaseRequestModel: FaxPurchaseRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaxApiInterface
     */
    receiveFax(options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {SendFaxRequestModel} sendFaxRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaxApiInterface
     */
    sendFax(sendFaxRequestModel: SendFaxRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaxApiInterface
     */
    sendNow(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {UpdateFaxRequestModel} updateFaxRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaxApiInterface
     */
    updateFax(updateFaxRequestModel: UpdateFaxRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {UpdateNumberRequestModel} updateNumberRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaxApiInterface
     */
    updateNumber(updateNumberRequestModel: UpdateNumberRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

}

/**
 * FaxApi - object-oriented interface
 * @export
 * @class FaxApi
 * @extends {BaseAPI}
 */
export class FaxApi extends BaseAPI implements FaxApiInterface {
    /**
     * 
     * @param {string} [countryCode] 
     * @param {string} [areacode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaxApi
     */
    public availableNumbers(countryCode?: string, areacode?: string, options?: RawAxiosRequestConfig) {
        return FaxApiFp(this.configuration).availableNumbers(countryCode, areacode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LamlRequestModel} lamlRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaxApi
     */
    public createLamlBin(lamlRequestModel: LamlRequestModel, options?: RawAxiosRequestConfig) {
        return FaxApiFp(this.configuration).createLamlBin(lamlRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaxApi
     */
    public deleteLamlBin(id?: string, options?: RawAxiosRequestConfig) {
        return FaxApiFp(this.configuration).deleteLamlBin(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaxApi
     */
    public deleteNumber(id: string, options?: RawAxiosRequestConfig) {
        return FaxApiFp(this.configuration).deleteNumber(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaxApi
     */
    public deleteSchedule(id: string, options?: RawAxiosRequestConfig) {
        return FaxApiFp(this.configuration).deleteSchedule(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaxApi
     */
    public faxStatusCallBack(options?: RawAxiosRequestConfig) {
        return FaxApiFp(this.configuration).faxStatusCallBack(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AllFaxRequestModel} allFaxRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaxApi
     */
    public listAllFaxes(allFaxRequestModel: AllFaxRequestModel, options?: RawAxiosRequestConfig) {
        return FaxApiFp(this.configuration).listAllFaxes(allFaxRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaxApi
     */
    public listLamlBins(id?: string, options?: RawAxiosRequestConfig) {
        return FaxApiFp(this.configuration).listLamlBins(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FaxQueueRequestModel} faxQueueRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaxApi
     */
    public moveFaxToQueue(faxQueueRequestModel: FaxQueueRequestModel, options?: RawAxiosRequestConfig) {
        return FaxApiFp(this.configuration).moveFaxToQueue(faxQueueRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaxApi
     */
    public numbers(id?: string, options?: RawAxiosRequestConfig) {
        return FaxApiFp(this.configuration).numbers(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FaxPurchaseRequestModel} faxPurchaseRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaxApi
     */
    public purchaseNumber(faxPurchaseRequestModel: FaxPurchaseRequestModel, options?: RawAxiosRequestConfig) {
        return FaxApiFp(this.configuration).purchaseNumber(faxPurchaseRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaxApi
     */
    public receiveFax(options?: RawAxiosRequestConfig) {
        return FaxApiFp(this.configuration).receiveFax(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SendFaxRequestModel} sendFaxRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaxApi
     */
    public sendFax(sendFaxRequestModel: SendFaxRequestModel, options?: RawAxiosRequestConfig) {
        return FaxApiFp(this.configuration).sendFax(sendFaxRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaxApi
     */
    public sendNow(id: string, options?: RawAxiosRequestConfig) {
        return FaxApiFp(this.configuration).sendNow(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateFaxRequestModel} updateFaxRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaxApi
     */
    public updateFax(updateFaxRequestModel: UpdateFaxRequestModel, options?: RawAxiosRequestConfig) {
        return FaxApiFp(this.configuration).updateFax(updateFaxRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateNumberRequestModel} updateNumberRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaxApi
     */
    public updateNumber(updateNumberRequestModel: UpdateNumberRequestModel, options?: RawAxiosRequestConfig) {
        return FaxApiFp(this.configuration).updateNumber(updateNumberRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}

