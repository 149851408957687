/* tslint:disable */
/* eslint-disable */
/**
 * Zsegment
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ErrorResponse } from '../Models';
// @ts-ignore
import { GetAllRoutesByInterface200Response } from '../Models';
// @ts-ignore
import { GetRouteById200Response } from '../Models';
// @ts-ignore
import { Start200Response } from '../Models';
/**
 * RouteControllerApi - axios parameter creator
 * @export
 */
export const RouteControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} interfaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRoutesByInterface: async (interfaceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'interfaceId' is not null or undefined
            assertParamExists('getAllRoutesByInterface', 'interfaceId', interfaceId)
            const localVarPath = `/routes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)

            if (interfaceId !== undefined) {
                localVarQueryParameter['interface_id'] = interfaceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} routeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRouteById: async (routeId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'routeId' is not null or undefined
            assertParamExists('getRouteById', 'routeId', routeId)
            const localVarPath = `/routes/{route_id}`
                .replace(`{${"route_id"}}`, encodeURIComponent(String(routeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} routeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        start: async (routeId: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'routeId' is not null or undefined
            assertParamExists('start', 'routeId', routeId)
            const localVarPath = `/routes/{route_id}/start`
                .replace(`{${"route_id"}}`, encodeURIComponent(String(routeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} routeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stop: async (routeId: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'routeId' is not null or undefined
            assertParamExists('stop', 'routeId', routeId)
            const localVarPath = `/routes/{route_id}/stop`
                .replace(`{${"route_id"}}`, encodeURIComponent(String(routeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RouteControllerApi - functional programming interface
 * @export
 */
export const RouteControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RouteControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} interfaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllRoutesByInterface(interfaceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllRoutesByInterface200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllRoutesByInterface(interfaceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RouteControllerApi.getAllRoutesByInterface']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} routeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRouteById(routeId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRouteById200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRouteById(routeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RouteControllerApi.getRouteById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string>} routeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async start(routeId: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Start200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.start(routeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RouteControllerApi.start']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string>} routeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stop(routeId: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Start200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stop(routeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RouteControllerApi.stop']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RouteControllerApi - factory interface
 * @export
 */
export const RouteControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RouteControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} interfaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRoutesByInterface(interfaceId: string, options?: any): AxiosPromise<GetAllRoutesByInterface200Response> {
            return localVarFp.getAllRoutesByInterface(interfaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} routeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRouteById(routeId: string, options?: any): AxiosPromise<GetRouteById200Response> {
            return localVarFp.getRouteById(routeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} routeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        start(routeId: Array<string>, options?: any): AxiosPromise<Start200Response> {
            return localVarFp.start(routeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} routeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stop(routeId: Array<string>, options?: any): AxiosPromise<Start200Response> {
            return localVarFp.stop(routeId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RouteControllerApi - interface
 * @export
 * @interface RouteControllerApi
 */
export interface RouteControllerApiInterface {
    /**
     * 
     * @param {string} interfaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RouteControllerApiInterface
     */
    getAllRoutesByInterface(interfaceId: string, options?: RawAxiosRequestConfig): AxiosPromise<GetAllRoutesByInterface200Response>;

    /**
     * 
     * @param {string} routeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RouteControllerApiInterface
     */
    getRouteById(routeId: string, options?: RawAxiosRequestConfig): AxiosPromise<GetRouteById200Response>;

    /**
     * 
     * @param {Array<string>} routeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RouteControllerApiInterface
     */
    start(routeId: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<Start200Response>;

    /**
     * 
     * @param {Array<string>} routeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RouteControllerApiInterface
     */
    stop(routeId: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<Start200Response>;

}

/**
 * RouteControllerApi - object-oriented interface
 * @export
 * @class RouteControllerApi
 * @extends {BaseAPI}
 */
export class RouteControllerApi extends BaseAPI implements RouteControllerApiInterface {
    /**
     * 
     * @param {string} interfaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RouteControllerApi
     */
    public getAllRoutesByInterface(interfaceId: string, options?: RawAxiosRequestConfig) {
        return RouteControllerApiFp(this.configuration).getAllRoutesByInterface(interfaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} routeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RouteControllerApi
     */
    public getRouteById(routeId: string, options?: RawAxiosRequestConfig) {
        return RouteControllerApiFp(this.configuration).getRouteById(routeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} routeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RouteControllerApi
     */
    public start(routeId: Array<string>, options?: RawAxiosRequestConfig) {
        return RouteControllerApiFp(this.configuration).start(routeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} routeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RouteControllerApi
     */
    public stop(routeId: Array<string>, options?: RawAxiosRequestConfig) {
        return RouteControllerApiFp(this.configuration).stop(routeId, options).then((request) => request(this.axios, this.basePath));
    }
}

