/**
 * @author Karthik <karthik.x@314ecorp.com>
 * @description Application providers
 */

import theme from '@dexit/admin/src/styles/theme';
import { ConfigProvider } from 'antd';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createInstance, MatomoProvider } from '@jonkoops/matomo-tracker-react';

import * as serviceWorker from './serviceWorkerRegistration';
import RQConfig from 'configs/react-query.config';
import ReleasePrompt from './ReleasePrompt';
import config from 'configs/index';

const matomoInstance = createInstance(config.analytics.matomo);

const withProviders = (Component: React.JSXElementConstructor<any>): React.FC<any> => {
	const queryClient = new QueryClient(RQConfig);

	const Providers: React.FC<any> = (props) => {
		return (
			<ConfigProvider theme={theme}>
				<MatomoProvider value={matomoInstance}>
					<QueryClientProvider client={queryClient}>
						<Component {...props} />
					</QueryClientProvider>
					<ReleasePrompt onApprove={(needToReload: boolean) => serviceWorker.approveUpdate(needToReload)} />
				</MatomoProvider>
			</ConfigProvider>
		);
	};
	return Providers;
};

export default withProviders;
