/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description custom hook to add pages to draft
 */

import _ from 'lodash';
import log from 'loglevel';
import { v4 as uuidv4 } from 'uuid';

import * as mupdf from '@dexit/common/mupdf/mupdf.js';
import pdfWorkerHelper from 'components/pdf/worker-helper';
import { Page, useIndexingActions, useIndexingValues } from 'store/indexing';

const useAddPagesToDraft = (): [
	(draftID: string, pagesToBeMoved: any, isFilesUploaded?: boolean) => Promise<Page[]>,
] => {
	const { draftDocuments } = useIndexingValues();
	const { updateDraftDocument, uploadFile } = useIndexingActions();

	const flattenPagesToMove = (pagesToBeMoved: any, isFilesUploaded?: boolean) => {
		if (isFilesUploaded) {
			return pagesToBeMoved;
		}
		if (_.isPlainObject(pagesToBeMoved)) {
			return _.flatten(Object.values(pagesToBeMoved));
		}
		return pagesToBeMoved;
	};
	const getPageId = (
		pageIndex: number,
		currentPageCount: number,
		flattenedPagesToMove: any,
		draftDoc: any,
		isFilesUploaded?: boolean,
	) => {
		if (pageIndex < currentPageCount) {
			return draftDoc?.pages?.[pageIndex]?.id ?? '-1';
		}
		if (isFilesUploaded) {
			return uuidv4();
		}
		const sourceIndex = pageIndex - currentPageCount;
		return flattenedPagesToMove[sourceIndex]?.id ?? uuidv4();
	};

	const addPagesToDraft = async (draftID: string, pagesToBeMoved: any, isFilesUploaded?: boolean) => {
		try {
			updateDraftDocument(draftID, { isUpdating: true });
			const draftDoc = _.find(draftDocuments, { id: draftID });
			if (!draftDoc) {
				throw new Error(`Draft document with ID ${draftID} not found`);
			}
			const pdfBytes = await pdfWorkerHelper.mergePDFToBaseFile(
				draftDoc?.blobUrl,
				pagesToBeMoved,
				isFilesUploaded,
			);
			URL.revokeObjectURL(draftDoc?.blobUrl);
			const blobUrl = URL.createObjectURL(new Blob([pdfBytes], { type: 'application/pdf' }));
			uploadFile({ filePath: draftDoc?.filePath ?? '', blobUrl });
			const pdf = new mupdf.PDFDocument(pdfBytes);
			const pageCount = pdf.countPages();
			const currentPageCount = draftDoc?.pages?.length ?? 0;
			const flattenedPagesToMove = flattenPagesToMove(pagesToBeMoved, isFilesUploaded);
			const newPages: Page[] = _.map(_.range(pageCount), (index) => {
				const pageId = getPageId(index, currentPageCount, flattenedPagesToMove, draftDoc, isFilesUploaded);
				return {
					id: pageId,
					pageNumber: index + 1,
					filePath: draftDoc?.filePath,
					isSelected: false,
				};
			});
			updateDraftDocument(draftID, {
				blobUrl,
				pages: newPages,
				isUpdating: false,
			});
			return newPages;
		} catch (error) {
			updateDraftDocument(draftID, { isUpdating: false });
			log.error('Error adding pages to draft:', error);
			throw error;
		}
	};

	return [addPagesToDraft];
};

export default useAddPagesToDraft;
