/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description Release prompt component to show a modal when a new version is available
 */

import React, { useEffect } from 'react';
import { Button, message, Space, Typography } from 'antd';
import { Info } from '@icon-park/react';

import EventBus, { Events } from '@dexit/common/utils/EventBus';

const compareVersions = (previousVersion: string | null, currentVersion: string): string => {
	if (!previousVersion || previousVersion === currentVersion) {
		return 'no-update';
	}
	return 'update';
};

interface IReleasePromptProps {
	onApprove: (needToReload: boolean) => void;
}

const ReleasePrompt: React.FC<IReleasePromptProps> = (props) => {
	const { onApprove } = props;
	const [messageApi, contextHolder] = message.useMessage();

	const handleOk = (version: string, needToReload: boolean) => {
		messageApi.destroy('release-prompt');
		onApprove(needToReload);
		localStorage.setItem('version', version);
	};

	const handleReleasePrompt = async (metadata: { version: string }) => {
		const previousVersion = localStorage.getItem('version') ?? APP_VERSION;
		const releaseType = compareVersions(previousVersion, metadata.version);
		switch (releaseType) {
			case 'no-update':
				handleOk(metadata.version, false);
				break;

			case 'update':
				await messageApi.open({
					key: 'release-prompt',
					icon: null,
					content: (
						<div className='flex-column-gap-4'>
							<Space direction='vertical' align='center' size={0}>
								<Space>
									<Info fill='#1677ff' theme='filled' size={16} />
									<Typography.Text style={{ fontSize: 16, margin: 0 }}>
										Your App has a new update
									</Typography.Text>
								</Space>
								<Typography.Text type='secondary'>
									A newer version is available. Save your changes before updating.
								</Typography.Text>
							</Space>

							<div className='flex-center'>
								<Button type='primary' onClick={() => handleOk(metadata.version, true)}>
									Update now
								</Button>
							</div>
						</div>
					),
					duration: 0,
				});
				break;

			default:
				handleOk(metadata.version, true);
		}
	};

	useEffect(() => {
		EventBus.subscribe(Events.RELEASE_PROMPT, handleReleasePrompt);
		return () => {
			EventBus.unsubscribe(Events.RELEASE_PROMPT);
		};
	}, []);

	return contextHolder;
};

export default ReleasePrompt;
