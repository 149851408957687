/* eslint-disable no-console */
/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description Service worker registration for web
 */

import _ from 'lodash';

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

type Config = {
	onUpdate: (registration: ServiceWorkerRegistration, metadata: { version: string }) => void;
};

const invokeServiceWorkerUpdateFlow = async (registration: ServiceWorkerRegistration, config: Config) => {
	try {
		const wBranchName = _.get(window, 'basePath', '');
		/* eslint-disable 314e/no-fetch-use-axios */
		await fetch(`${window.location.origin}${wBranchName}manifest.json`)
			.then((res) => res.json())
			.then((manifest) => {
				config.onUpdate(registration, {
					version: manifest?.version ?? APP_VERSION,
				});
			});
	} catch (error) {
		console.log('No internet connection found. App is running in offline mode.');
		config.onUpdate(registration, { version: APP_VERSION });
	}
};

const registerValidSW = async (swUrl: string, config: Config) => {
	try {
		await navigator.serviceWorker.register(swUrl).then(async (registration) => {
			const localhost = ENVIRONMENT === 'localhost';
			const isIntegration = localhost || ENVIRONMENT === 'integration';
			if (isIntegration) {
				if (registration.waiting) {
					registration.waiting.postMessage('SKIP_WAITING');
				}

				registration.addEventListener('updatefound', () => {
					const installingWorker = registration.installing;
					if (installingWorker) {
						installingWorker.addEventListener('statechange', () => {
							if (installingWorker.state === 'installed' && registration.waiting) {
								registration.waiting.postMessage('SKIP_WAITING');
							}
						});
					}
				});
			} else {
				// Check for updates at start.
				await registration.update();
				// Check for updates every min.
				setInterval(() => {
					void registration.update().catch((error) => console.warn(error));
				}, 1000 * 60);

				// ensure the case when the updatefound event was missed is also handled
				// by re-invoking the prompt when there's a waiting Service Worker
				if (registration.waiting) {
					await invokeServiceWorkerUpdateFlow(registration, config).catch((error) => {
						console.error('Error during service worker update flow:', error);
					});
				}
				registration.onupdatefound = () => {
					const installingWorker = registration.installing;
					if (installingWorker) {
						installingWorker.onstatechange = async () => {
							if (installingWorker.state === 'installed') {
								await invokeServiceWorkerUpdateFlow(registration, config).catch((error) => {
									console.error('Error during service worker update flow:', error);
								});
							}
						};
					}
				};
			}
		});
	} catch (error) {
		console.error('Error during service worker registration :', error);
	}
};

const checkValidServiceWorker = async (swUrl: string, config: Config) => {
	try {
		/* eslint-disable 314e/no-fetch-use-axios */
		const response = await fetch(swUrl, {
			headers: { 'Service-Worker': 'script' },
		});

		const contentType = response.headers.get('content-type');
		if (response.status === 404 || (contentType != null && contentType.indexOf('javascript') === -1)) {
			const registration = await navigator.serviceWorker.ready;
			await registration.unregister();
			window.location.reload();
		} else {
			await registerValidSW(swUrl, config);
		}
	} catch (error) {
		console.log('No internet connection found. App is running in offline mode.');
	}
};

const register = (config: Config): void => {
	if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
		const wBranchName = _.get(window, 'basePath', ''); // eg: /sprint/
		window.addEventListener('load', () => {
			const swUrl = `${wBranchName}sw.js`;

			if (ENVIRONMENT === 'localhost') {
				checkValidServiceWorker(swUrl, config)
					.then(() => {
						void navigator.serviceWorker.ready.then(() => {
							console.log(
								'This web app is being served cache-first by a service ' +
									'worker. To learn more, visit https://cra.link/PWA',
							);
						});
					})
					.catch((error) => {
						console.error('Error during service worker registration:', error);
					});
			} else {
				registerValidSW(swUrl, config)
					.then(() => {
						console.log('Service worker registered successfully.');
					})
					.catch((error) => {
						console.error('Error during service worker registration:', error);
					});
			}
		});
	}
};

const unregister = async (): Promise<void> => {
	if ('serviceWorker' in navigator) {
		try {
			const registration = await navigator.serviceWorker.ready;
			await registration.unregister();
		} catch (error: any) {
			console.error(error.message);
		}
	}
};

const approveUpdate = async (needToReload: boolean): Promise<void> => {
	if ('serviceWorker' in navigator) {
		try {
			const registration = await navigator.serviceWorker.ready;
			if (registration.waiting) {
				registration.waiting.postMessage('SKIP_WAITING');
				if (needToReload) {
					window.location.reload();
				}
			}
		} catch (error: any) {
			console.error(error.message);
		}
	}
};

export { register, unregister, approveUpdate };
